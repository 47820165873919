var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"you-tube-embed"},[(_vm.isPlaying && _vm.isAllowed)?_c('iframe',{attrs:{"src":`https://www.youtube-nocookie.com/embed/${_vm.videoId}?autoplay=1&rel=0`,"frameborder":"0","allow":"accelerometer;\n      autoplay;\n      clipboard-write;\n      encrypted-media;\n      gyroscope;\n      picture-in-picture","allowfullscreen":""}}):_vm._e(),(!_vm.isPlaying)?[_c('TipPreviewImage',{attrs:{"tip-url":_vm.tipUrl,"image":`https://i.ytimg.com/vi/${_vm.videoId}/hqdefault.jpg`,"source":_vm.sourceUrl,"title":_vm.tipPreviewTitle,"description":_vm.tipPreviewDescription}}),_c('PlayButton',{on:{"click":function($event){_vm.isAllowed
        ? _vm.isPlaying = true
        : _vm.$store.dispatch('modals/open', {
          name: 'cookies-dialog',
          reference: _vm.$el,
          scope: 'YouTube',
        })}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }