<template>
  <iframe
    class="governance"
    src="https://governance.aeternity.com/#/?iframe=1"
  />
</template>

<script>
export default {
  metaInfo: {
    title: 'Superhero Voting',
  },
};
</script>

<style lang="scss" scoped>
.governance {
  border: none;
}
</style>
