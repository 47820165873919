var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-section"},[_c('SearchFeed',{staticClass:"section",attrs:{"sided":""}}),_c('RightSectionWallet',_vm._b({staticClass:"section"},'RightSectionWallet',_vm.toggleable && {
      closed: _vm.showTopics,
      toggleHandler: () => _vm.showTopics = !_vm.showTopics,
    },false)),_c('RightSectionTopics',_vm._b({staticClass:"section"},'RightSectionTopics',_vm.toggleable && {
      closed: !_vm.showTopics,
      toggleHandler: () => _vm.showTopics = !_vm.showTopics,
    },false)),_c('FooterSection')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }