var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-input"},[_c('ButtonPlain',{class:{ tipped: _vm.tipUrlStats.isTipped },attrs:{"to":_vm.useSdkWallet ? undefined : _vm.deepLink,"title":_vm.title,"disabled":!_vm.tipUrl},on:{"click":_vm.openTipInputPopup}},[_c('IconTip'),(!_vm.userAddress && _vm.tipAmount.value)?_c('AeAmountFiat',{attrs:{"amount":_vm.tipAmount.value,"token":_vm.tipAmount.token}}):_vm._e()],1),(_vm.showTokenDropdown)?_c('ButtonDropdown',{ref:"tokensOpener",on:{"click":function($event){return _vm.$store.dispatch('modals/open', {
      name: 'token-select',
      reference: _vm.$refs.tokensOpener.$el,
      notSelectable: true,
      tokens: _vm.tipUrlStats.tokenTotalAmount,
      inEnd: true,
    })}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }