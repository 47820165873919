var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sound-cloud-embed"},[_c('img',{attrs:{"src":_vm.tipPreviewImage}}),_c('TipUrlDetails',{attrs:{"source":_vm.sourceUrl,"title":_vm.tipPreviewTitle,"description":_vm.tipPreviewDescription}},[_c('a',{attrs:{"href":_vm.tipUrl,"target":"_blank"}}),(!_vm.isPlaying)?_c('PlayButton',{on:{"click":function($event){_vm.isAllowed
        ? _vm.isPlaying = true
        : _vm.$store.dispatch('modals/open', {
          name: 'cookies-dialog',
          reference: _vm.$el,
          scope: 'SoundCloud',
        })}}}):(_vm.isPlaying && _vm.isAllowed)?_c('SoundCloudPlayer',{attrs:{"tip-url":_vm.tipUrl}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }